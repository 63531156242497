import React, { useDebugValue, useEffect, useState } from 'react'
import Button from '../../../component/ui/Button'
import { useNavigate } from 'react-router-dom'
import { InventoryAdjustmentTableColoum } from './InventoryAdjustmentColumn'
import Table from '../../../component/table/Table'
import sortbydot from '../../../assets/image/sort_by_group.svg'
import moment from 'moment'
import InputField from '../../../component/form/FormInput'
import Modal from '../../../component/modal/Modal'
import CustumDateSelect from './CustumDateSelect'
import { useDispatch, useSelector } from 'react-redux'
import { inventoryAdjustmentList } from '../../../store/action/inventory'
import Pagination from '../../../component/pagination'

const InventoryAdjustment = () => {
  // const suburl = 'Hello'
  const suburl = localStorage.getItem('suburl');
  const dispatch = useDispatch()
  const [customDate, setCustomDate] = useState({ fromDate: null, toDate: null });
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [headerDropDown, setHeaderDropDown] = useState('All')
  const [filterparam, setFilterparam] = useState('')
  const [sortParam, setSortParam] = useState('')
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const navigate = useNavigate()
  const [inventoryAdjustMentList, setInventoryAdjustMentList] = useState([])
  const pageSize = 20
  useEffect(() => {
    let Tdata = []
    // console.log('use effect running')
    let FilterData = inventoryAdjustMentList?.inventoryAdjustment?.filter((item) => {
      return item?.is_deleted === 0 && item?.is_draft !== 2
    })
    // console.log('FilterData', FilterData)
    FilterData?.map((data) => {
      Tdata?.push({
        id: data?.adjustment_id,
        DATE: moment(data?.created_at).format('DD/MM/YYYY'),
        REASON: data?.reason?.reason_description,
        DESCRIPTON: data?.description,
        STATUS: data?.is_draft === 0 ? 'Draft' : 'Converted',
        'REFERENCE NUMBER': data?.reference_number,
        'ACCOUNT': data?.account?.account_name,
        "CREATED TIME": moment(data?.created_at).format('DD/MM/YYYY, hh:mm A')
      })
    })
    setTableData(Tdata)
    let toatlPages = Math.ceil(inventoryAdjustMentList?.totalCount / pageSize)
    setTotalPages(toatlPages)
  }, [inventoryAdjustMentList])
  const navigateRouter = (path) => {
    navigate(path)
  }
  const onRowClick = (id) => {
    if (id) {
      navigate(`/user/${suburl}/inventory-adjustment-crete/${id}`)
      window.location.reload()
    }
  }

  const getDateRange = (filter) => {
    const today = moment().startOf('day');
    let fromDate, toDate;
    switch (filter) {
      case 'Today':
        fromDate = today;
        toDate = moment(fromDate).endOf('day');
        break;
      case 'week':
        fromDate = moment(today).startOf('isoWeek');
        toDate = moment(today).endOf('isoWeek');
        break;
      case 'month':
        fromDate = moment(today).startOf('month');
        toDate = moment(today).endOf('month');
        break;
      case 'quarter':
        fromDate = moment(today).startOf('quarter');
        toDate = moment(today).endOf('quarter');
        break;
      case 'year':
        fromDate = moment(today).startOf('year');
        toDate = moment(today).endOf('year');
        break;
      case 'fiscal':
        fromDate = moment(today).month() >= 3 ? moment(today).startOf('year').month(3).startOf('month') : moment(today).subtract(1, 'year').month(3).startOf('month');
        toDate = moment(fromDate).add(1, 'year').subtract(1, 'day');
        break;
      case 'custom':
        fromDate = customDate.fromDate;
        toDate = customDate.toDate;
        break;
      default:
        fromDate = null;
        toDate = null;
        break;
    }

    return {
      fromDate: fromDate ? fromDate.format('YYYY-MM-DD') : null,
      toDate: toDate ? toDate.format('YYYY-MM-DD') : null
    };
  };

  const filterData = (filter, data) => {
    setHeaderDropDown(data)
    setSortParam('')
    if (filter === 'custum') {
      setShowCustomDatePicker(true);
    } else {
      const { fromDate, toDate } = getDateRange(filter);
      let param = `startDate=${fromDate}&endDate=${toDate}&filterBy=date`
      if (filter === '') {
        param = ''
      }
      setFilterparam(param)
      setShowCustomDatePicker(false);
      // Handle the date filtering logic here
    }
  };
  const getInventoryDetails = () => {
    setLoading(true)
    let param = `${filterparam}${filterparam && '&'}${sortParam}${sortParam && "&"}page=${currentPage}&pageSize=${pageSize}`
    dispatch(inventoryAdjustmentList(param)).then((res) => {
      if (res?.success) {
        // console.log('res?.data?.data', res?.data?.data)
        setInventoryAdjustMentList(res?.data?.data)
        setLoading(false)
      } else {
        setInventoryAdjustMentList([])
        setLoading(false)
      }
    }).catch((err) => {
      setInventoryAdjustMentList([])
      setLoading(false)
      // console.log('errror', err)

    })

  }
  useEffect(() => {
    getInventoryDetails()
  }, [dispatch, sortParam, filterparam, currentPage])
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const sortData = (Param) => {
    setSortParam(Param)
  }
  return (
    <div>
      <div className='product-pg-header add-product'>
        <div className="dropdown text-end">
          <a className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            <p>{headerDropDown}</p>
          </a>
          <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
            <li><a className="dropdown-item" onClick={() => filterData('', 'All')}>All</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('Today', 'Today')}>Today</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('week', 'This Week')}>This Week</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('month', 'This Month')}>This Month</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('quarter', 'This Quarter')}>This Quarter</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('year', 'This Year')}>This Year</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('fiscal', 'This Fiscal Year')}>This Fiscal Year</a></li>
            <li><a className="dropdown-item" onClick={() => filterData('custum', 'Custom Date')}>Custom Date</a></li>
          </ul>
          {showCustomDatePicker && <div>
            <Modal className="add-productgroup-modal" isOpen={showCustomDatePicker} onClose={() => setShowCustomDatePicker(false)} title="Select Custom Date">
              <CustumDateSelect isOpen={showCustomDatePicker} onCLose={() => setShowCustomDatePicker(false)} reloadTable={''} setFilterparam={setFilterparam} />
            </Modal></div>
          }
        </div>

        <div className='add-product-button column-gap-0'>
          <Button type="button" className="btn-primary mt-0 px-5" button="Create New Adjustment" add onClick={() => navigateRouter(`/user/${suburl}/inventory-adjustment-crete`)} />
          <div className="dropdown text-end ms-3">
            <a href="#" className="d-block link-dark text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={sortbydot} />
            </a>
            {/* date , reason, created_at , description,status,reference_number, */}
            <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
              <li><a className="dropdown-item sortBy" >Sort By</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=date&sortBy=asc`)}>Date</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=reason&sortBy=asc`)}>Reason</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=created_at&sortBy=asc`)}>Created Time</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=description&sortBy=asc`)}>Description</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=status&sortBy=asc`)}>Status</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=reference_number&sortBy=asc`)}>Reference Number</a></li>
              {/* <li><a className="dropdown-item" onClick={() => sortData(`orderBy=reason&sortBy=asc`)}>Created By</a></li> */}
              {/* <li><a className="dropdown-item" onClick={() => sortData(`orderBy=reason&sortBy=asc`)}>Created Time</a></li> */}
            </ul>
          </div>
        </div>

      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', marginTop: "20%" }}>
          <span className="Page_loader" role="status" aria-hidden="true"></span>
        </div>
      ) : <div className='productTable-body'>
        <Table row={tableData} coloum={InventoryAdjustmentTableColoum} className="productTable" onRowClick={onRowClick} />
      </div>}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />
    </div>
  )
}

export default InventoryAdjustment