import React, { useEffect, useState } from 'react'
import Table from '../../../../component/table/Table'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../../../component/ui/Button'
import Modal from '../../../../component/modal/Modal'
import AddProductGroup from './AddProductGroup'
import '../Product.scss';
import { product_group_list } from '../../../../store/action/group'
import { useDispatch, useSelector } from 'react-redux'
import sortbydot from '../../../../assets/image/sort_by_group.svg'
import { productGroupColoum } from './productGroupColoum'
import { ExportTable } from '../../../../store/action/export/Export'
import Pagination from '../../../../component/pagination'
import BulkImport from '../BulkImport'


const ProductGroup = () => {
  
  // const suburl = 'Hello'
  const suburl = localStorage.getItem('suburl');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [headerDropDown, setHeaderDropDown] = useState('All Group')
  const [filterparam, setFilterparam] = useState('')
  const [sortParam, setSortParam] = useState('')
  const [open, setOpen] = useState(false)
  const [buklImport, setIsbuklImport] = useState(false)
  const [loading, setLoading] = useState(false)
  const [productgroupData, SetProductGroupData] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const productgroupData = useSelector(state => state?.productGroup?.data?.data)
  const [row, setRow] = useState([])

  const navigateRouter = (path) => {
    navigate(path)
  }
  const groupList = () => {
    let param = `${filterparam}${filterparam && '&'}${sortParam}${sortParam && "&"}page=${currentPage}&pageSize=20`
    setLoading(true)
    dispatch(product_group_list(param)).then((res) => {
      if (res?.success) {
        // console.log('success', res?.data?.productGroups)
        SetProductGroupData(res?.data)
        setLoading(false)
      } else {
        setLoading(false)
        SetProductGroupData([])

      }
    }).catch((err) => {
      SetProductGroupData([])
      setLoading(false)
      // console.log(err)
    })
  }
  useEffect(() => {
    groupList()
  }, [currentPage, sortParam, filterparam])
  useEffect(() => {
    let data = []
    productgroupData?.productGroups?.map((ele) => {
      data?.push({ id: ele?.product_group_id, 'Group Name': ele?.name, 'Group Code': ele?.code, 'Product Count': ele?.product_count })
    })
    setRow(data)
    let toatlPages = Math.ceil(productgroupData?.totalCount / 20)
    setTotalPages(toatlPages)
  }, [productgroupData])
  const handleRowClick = (id) => {
    // navigateRouter(`/user/${suburl}/product/${id}`)
    navigateRouter(`/user/${suburl}/group-details/${id}`)
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);

  };
  const filterData = (param, data) => {
    setFilterparam(param)
    setHeaderDropDown(data)
  }
  const sortData = (param) => {
    setSortParam(param)
  }
  return (
    <div>

      <div className='product-pg-header'>
        <div className="dropdown text-end">
          <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            <p>{headerDropDown}</p>
          </a>
          <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
            <li><a className="dropdown-item" onClick={() => filterData(``, 'All Groups')}>All Groups</a></li>
            <li><a className="dropdown-item" onClick={() => filterData(`filterBy=status&filterValue=1`, 'Active Groups')}>Active Groups</a></li>
            <li><a className="dropdown-item" onClick={() => filterData(`filterBy=status&filterValue=0`, 'Inactive Groups')}>Inactive Groups</a></li>
          </ul>
        </div>


        <div className='d-flex align-items-center'>
          <Button add type="button" button="Create New Grop" className="btn-primary mt-0 px-4" onClick={() => setOpen(true)} />

          <div className="dropdown text-end ms-3">
            <a href="#" className="d-block link-dark text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={sortbydot} />

            </a>
            <ul className="dropdown-menu font-14" aria-labelledby="dropdownUser1" style={{ minWidth: '240px' }}>
              <li><a className="dropdown-item sortBy">Sort By</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=created_at&sortBy=asc`,)}>Created Time</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(`orderBy=name&sortBy=asc`,)}>Name</a></li>
              <hr />
              <li><a className="dropdown-item" onClick={() => setIsbuklImport(true)}>Import Group</a></li>
              <li><a className="dropdown-item" onClick={() => ExportTable('product_group')}>Export Group</a></li>
              <hr />
              <li><a className="dropdown-item" onClick={() => sortData(``,)}>Preferences</a></li>
              <li><a className="dropdown-item" onClick={() => sortData(``,)}>Refresh</a></li>
            </ul>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', marginTop: "20%" }}>
          <span className="Page_loader" role="status" aria-hidden="true"></span>
        </div>
      ) : <div className='productTable-body'>
        <Table row={row} coloum={productGroupColoum} className="productTable" onRowClick={handleRowClick} />
      </div>}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />
      {open && <div>
        <Modal className="add-productgroup-modal" isOpen={open} onClose={() => setOpen(false)} title="Add New Group">
          <AddProductGroup isOpen={open} onCLose={() => setOpen(false)} reloadTable={groupList} />
        </Modal></div>
      }
      {buklImport && <div>
        <Modal className="add-productgroup-modal" isOpen={buklImport} onClose={() => setIsbuklImport(false)} title=" Group bulk Import ">
          <BulkImport isOpen={buklImport} onClose={() => setIsbuklImport(false)} reloadTable={groupList} type={'group'} />
        </Modal></div>
      }
    </div>
  )
}

export default ProductGroup