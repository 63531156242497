// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.error-code {
  font-size: 5rem;
  font-weight: bold;
  color: #f44336;
  margin-bottom: 20px;
}

.error-message {
  font-size: 2rem;
  color: #333;
  margin-bottom: 40px;
}

.back-home-link .link-text {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.back-home-link .link-text:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/pages/404NotFound/NotFound.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,8BAAA;AADJ;;AAIE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AADJ;;AAIE;EACE,eAAA;EACA,WAAA;EACA,mBAAA;AADJ;;AAIE;EACE,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iCAAA;AADJ;;AAIE;EACE,yBAAA;AADJ","sourcesContent":["// NotFound.scss\n\n.not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    font-family: Arial, sans-serif;\n  }\n  \n  .error-code {\n    font-size: 5rem;\n    font-weight: bold;\n    color: #f44336;\n    margin-bottom: 20px;\n  }\n  \n  .error-message {\n    font-size: 2rem;\n    color: #333;\n    margin-bottom: 40px;\n  }\n  \n  .back-home-link .link-text {\n    color: #fff;\n    text-decoration: none;\n    background-color: #007bff;\n    padding: 10px 20px;\n    border-radius: 5px;\n    font-size: 1.2rem;\n    transition: background-color 0.3s;\n  }\n  \n  .back-home-link .link-text:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
