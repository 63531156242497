import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";
import { toast } from "react-toastify";

export const EXPORT_TABLE_DONE = "EXPORT_TABLE_DONE";
export const EXPORT_TABLE_FAIL = "EXPORT_TABLE_FAIL";

export const EXPORT_TABLE_API_URL = `${APP_API_URL}/app/export-table`;

let token = getLocalStorageData();
const config = {
  method: "GET", // or 'POST', 'PUT', etc., depending on what the API expects
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token?.token}`,
  },
  responseType: "blob", // Ensure the response is treated as a blob
};

export const ExportTable = async (param) => {
  let API_URL = `${EXPORT_TABLE_API_URL}?type=${param}`;
  try {
    const response = await axios.get(API_URL, config);
    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${param}_table.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    toast.success("Downloaded successfully");
  } catch (error) {
    // console.log()
    // console.error("Error downloading the file:", error);
    toast.error("Error! unable to  downloading the file.");
  }
};
