// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slide-down 0.3s ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.modal-body {
  padding: 10px 20px 20px 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/component/modal/modal.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AAEE;EACE,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,yCAAA;EACA,4CAAA;AACJ;;AAEE;EACE;IACE,UAAA;IACA,2BAAA;EACJ;EACE;IACE,UAAA;IACA,wBAAA;EACJ;AACF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AAAJ;;AAGE;EACE,4BAAA;AAAJ;;AAGE;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,2BAAA;AAAJ;;AAGE;EACE,WAAA;AAAJ","sourcesContent":[".modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  \n  .modal-content {\n    background: white;\n    border-radius: 8px;\n    width: 80%;\n    max-width: 500px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n    animation: slide-down 0.3s ease-out forwards;\n  }\n  \n  @keyframes slide-down {\n    from {\n      opacity: 0;\n      transform: translateY(-10%);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n  \n  .modal-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .modal-body {\n    padding: 10px 20px 20px 20px;\n  }\n  \n  .close-button {\n    background: none;\n    border: none;\n    font-size: 1.5rem;\n    cursor: pointer;\n    color: #aaa;\n    transition: color 0.3s ease;\n  }\n  \n  .close-button:hover {\n    color: #000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
