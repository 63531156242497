export const ObjectCreate = ({ data, key, value }) => {
    // console.log('data of batch ', data)
    return data?.map(item => {
        return {
            ['key']: item[key],
            ['value']: `${item[value]}`
        };
    });
};

export const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
