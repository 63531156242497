import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss'; // Import your CSS file

const Pagination = ({ currentPage, totalPages, onChangePage }) => {
    // console.log('totalPages', totalPages);

    const getVisiblePages = () => {
        const visiblePages = [];
        const totalPagesToShow = 5; // Number of pages to show at a time

        let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

        if (endPage - startPage < totalPagesToShow - 1) {
            startPage = Math.max(1, endPage - totalPagesToShow + 1);
        }

        for (let page = startPage; page <= endPage; page++) {
            visiblePages.push(page);
        }

        return visiblePages;
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 37) {
            // Left arrow key
            if (currentPage > 1) {
                onChangePage(currentPage - 1);
            }
        } else if (event.keyCode === 39) {
            // Right arrow key
            if (currentPage < totalPages) {
                onChangePage(currentPage + 1);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentPage, totalPages]);

    return (
        <div className="pagination-container">
            <nav aria-label="Page navigation">
                {isNaN(totalPages) ? " " : <span style={{ marginRight: "25px" }}><span style={{ marginRight: "5px" }}>Page</span><b style={{ marginRight: "2px" }}>{currentPage}</b> of <b style={{ marginLeft: "2px" }}>{totalPages}</b></span>}
                {isNaN(totalPages) ? "" : <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => onChangePage(currentPage - 1)} aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </button>
                    </li>
                    {getVisiblePages().map(page => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => onChangePage(page)}>{page}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => onChangePage(currentPage + 1)} aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </button>
                    </li>
                </ul>}
            </nav>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
};

export default Pagination;
