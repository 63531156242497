import React from "react";
import InputTable from "../../../component/InputTable/InputTable";
import InventoryAdjustmentTable from "../../../component/InputTable/InventoryAdjustmentTable";

const AdjustmentTable = ({
  tableData,
  setTableData,
  disabled,
  paramId,
  tableError,
  setIsTableErrorShow,
}) => {
  const handleTableChange = (newData) => {
    setTableData(newData);
    // console.log('new Data', newData)
  };

  const columns = [
    { name: "product_id", label: "Name", type: "product_name" },
    { name: "batch_id", label: "Batch No", type: "batch_no" },
    { name: "qty", label: "Quantity Available", type: "text" },
    { name: "quantity_adjusted", label: "Quantity Adjusted", type: "text" },
    { name: "qtyHand", label: "Quantity In Hand", type: "text" },
  ];

  let initialRows = [
    {
      product_id: "",
      batch_id: "",
      quantity_adjusted: "",
      adjustment_details_id: "",
      qty: "",
      qtyHand: "",
    },
  ];

  // console.log('tableData', tableData)
  return (
    <div
      className="add-adjustment-inputTable max-w-1297"
      style={{ padding: "0rem 2rem" }}
    >
      {tableData && (
        <InventoryAdjustmentTable
          initialRows={initialRows}
          columns={columns}
          onChange={handleTableChange}
          value={tableData}
          disabled={disabled}
          newRowgenerate={"Quantity Adjusted"}
          returnInputvalue={false}
          paramId={paramId}
          tableError={tableError}
          setIsTableErrorShow={setIsTableErrorShow}
        />
      )}
    </div>
  );
};

export default AdjustmentTable;
