export const productGroupColoum = [
    // {
    //     field: "",
    //     headerClass: 'HeaderBg',
    //     checkboxSelection: true,
    //     headerCheckboxSelection: true,
    //     minWidth: 50,
    //     maxWidth:50,
    //     lockPosition: true,
    // },
    {
        field: "Group Name",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 200,
        flex: 1
    },
    {
        field: "Group Code",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1
    },
    {
        field: "Product Count",
        headerClass: 'HeaderBg',
        suppressSizeToFit: true,
        minWidth: 150,
        flex: 1,
        cellClass: 'right-align',
        headerClass: 'HeaderBg right-align',
    },
    // {
    //     field: "REORDER LEVE",
    //     headerClass: 'HeaderBg',
    //     suppressSizeToFit: true,
    //     minWidth: 150,
    //     flex: 1
    // },
    // {
    //     field: "ACTION",
    //     headerClass: 'HeaderBg',
    //     suppressSizeToFit: true,
    //     minWidth: 150,
    //     flex: 1
    // }
]