
import React, { useState, useEffect } from 'react';
import './Layout.scss';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/image/admin-nav-logo.png'

// const suburl = `Hello`
const suburl = localStorage.getItem('suburl');
const menuItems = [
  { label: 'Home', path: `/user/${suburl}/home` },
  {
    label: 'Inventory',
    path: `/user/${suburl}/product`,
    subMenuItems: [
      { label: 'Product', path: `/user/${suburl}/product` },
      { label: 'Groups', path: `/user/${suburl}/group` },
      { label: 'Adjustments', path: `/user/${suburl}/inventory-adjustment` },
    ],
  },
  {
    label: 'Sales',
    path: `/user/${suburl}/customers`,
    subMenuItems: [
      { label: 'Customers', path: `/user/${suburl}/customers` },
      { label: 'Doctors', path: `/user/${suburl}/doctors` },
      { label: 'Invoices', path: `/user/${suburl}/invoices` },
      { label: 'Payments Received', path: `/user/${suburl}/payments-received` },
      { label: 'Sales Return', path: `/user/${suburl}/sales-return` },
      { label: 'Credit Notes', path: `/user/${suburl}/credit-notes` },
    ],
  },
  {
    label: 'Purchases',
    path: `/user/${suburl}/purchases1`,
    subMenuItems: [
      { label: 'Purchases1', path: `/user/${suburl}/purchases1` },
      { label: 'Purchases2', path: `/user/${suburl}/purchases2` },
    ],
  },
  {
    label: 'Reports',
    path: `/user/${suburl}/reports1`,
    subMenuItems: [
      { label: 'Reports1', path: `/user/${suburl}/reports1` },
      { label: 'Reports2', path: `/user/${suburl}/reports2` },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const currentPath = location.pathname;
    const activeMenu = menuItems.find((menuItem) =>
      menuItem.subMenuItems
        ? menuItem.subMenuItems.some((subMenuItem) => currentPath.startsWith(subMenuItem.path))
        : currentPath.startsWith(menuItem.path)
    );
    if (activeMenu) {
      setOpenKeys([activeMenu.label]);
    }
  }, [location.pathname]);
useEffect(()=>{

},[location.pathname])
  const isActive = (path) => {
    return location.pathname.includes(path) ? 'active' : '';
  };

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevKeys) => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter((k) => k !== key);
      }
      return [...prevKeys, key];
    });
  };

  const navigateRoute = (path)=>{
    navigate(path)
  }
  return (
    <div>
      <Menu className='side-menu'>
        <div>
          <div className="brand-name-sidebar">
            {/* <span>Pharm</span><span>Nex</span> */}
            <div className="brand-name"><img src={logo} alt="" /></div>
          </div>
        </div>
        <span className='menu-items'>
          {menuItems.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem.subMenuItems ? (
                <SubMenu
                  label={menuItem.label}
                  open={openKeys.includes(menuItem.label)}
                  onOpenChange={() => handleSubMenuClick(menuItem.label)}
                >
                  {menuItem.subMenuItems.map((subMenuItem, subIndex) => (
                    <MenuItem key={subIndex} className={isActive(subMenuItem.path)} onClick={()=>navigateRoute(subMenuItem.path)}>
                      <Link to={subMenuItem.path}>{subMenuItem.label}
                      </Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem key={index} className={isActive(menuItem.path)} onClick={()=>navigateRoute(menuItem.path)}>
                  <Link to={menuItem.path}>{menuItem.label}</Link>
                </MenuItem>
              )}
            </React.Fragment>
          ))}
        </span>
      </Menu>
    </div>
  );
};

export default Sidebar;

