import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ImageRenderer from "../../../component/imageRenderer/imageRnder";

const suburl = localStorage.getItem('suburl');

const ProductTable = ({ row, onRowDataChange, action, className, coloum, onRowClick, rowSelection }) => {
    const gridRef = useRef();
    const navigate = useNavigate();

    // Handle selection change
    const onSelectionChanged = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        let id = [];
        selectedData.forEach((ele) => {
            id.push(ele.id);
        });
        action(id);
    };

    // Handle cell click
    const onCellClicked = (params) => {
        const colId = params.column.colId;
        // Check if the clicked cell is in the first column (checkbox column)
        if (colId !== coloum[0].field) {
            // console.log('Cell Clicked:', params);
            // console.log('Row Data:', params.data);
            // console.log('Custom Props:', params.colDef.customProps); // If you have any custom props
            navigate(`/user/${suburl}/product-create/${params?.data?.id}`);
        }
    };

    // Handle cell value changed
    const onCellValueChanged = (params) => {
        // console.log('Data after change:', params.data);
        if (onRowDataChange) {
            onRowDataChange(params.data);
        }
    };

    // Navigate to previous row
    const navigateToPreviousRow = () => {
        const focusedCell = gridRef.current.api.getFocusedCell();
        if (focusedCell && focusedCell.rowIndex > 0) {
            const previousRowIndex = focusedCell.rowIndex - 1;
            gridRef.current.api.setFocusedCell(previousRowIndex, focusedCell.column.colId);
        }
    };

    // Navigate to next row
    const navigateToNextRow = () => {
        const focusedCell = gridRef.current.api.getFocusedCell();
        if (focusedCell && focusedCell.rowIndex < row.length - 1) {
            const nextRowIndex = focusedCell.rowIndex + 1;
            gridRef.current.api.setFocusedCell(nextRowIndex, focusedCell.column.colId);
        }
    };

    // Handle key down event for navigation
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const focusedCell = gridRef.current.api.getFocusedCell();
            if (focusedCell) {
                const colId = focusedCell.column.colId;
                // Check if the focused cell is in the first column (checkbox column)
                if (colId !== coloum[0].field) {
                    const params = {
                        ...focusedCell,
                        data: gridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex).data,
                        colDef: focusedCell.column.getColDef()
                    };
                    onCellClicked(params);
                } else {
                    // console.log('Enter Is working ')
                    onSelectionChanged()
                }
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault(); // Prevent default scrolling behavior
            navigateToPreviousRow();
        } else if (event.key === 'ArrowDown') {
            event.preventDefault(); // Prevent default scrolling behavior
            navigateToNextRow();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Focus the first cell in the first column after data is rendered
    const onFirstDataRendered = () => {
        gridRef.current.api.setFocusedCell(0, coloum[0].field);
    };

    // Handle row click for row selection
    const onRowClicked = (event) => {
        if (event && event.column) {
            const colId = event.column.colId;
            // Check if the clicked cell is in the first column (checkbox column)
            if (colId !== coloum[0].field) {
                const rowData = event.data;
                const selected = event.node.isSelected();

                if (selected) {
                    event.node.setSelected(false);
                } else {
                    event.node.setSelected(true);
                }

                if (onRowClick) {
                    onRowClick(rowData.id); // Adjust this to pass any necessary data to the onRowClick handler
                }
            }
        }
    };


    return (
        <div className='planDetail-body'>
            <div className="ag-theme-quartz" style={{ height: '200px' }}>
                <div className="ag-grid-table">
                    <AgGridReact
                        ref={gridRef}
                        rowData={row}
                        className={className}
                        columnDefs={coloum}
                        suppressRowClickSelection={true}
                        onSelectionChanged={onSelectionChanged}
                        onCellClicked={onCellClicked}
                        onCellValueChanged={onCellValueChanged}
                        rowSelection="multiple"
                        frameworkComponents={{
                            imageRenderer: ImageRenderer,
                        }}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowClicked={onRowClicked} // Handle row click for row selection
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductTable;
