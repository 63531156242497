import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

export const PRODUCT_CREATE_DONE = "PRODUCT_CREATE_DONE";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";

export const PRODUCT_LIST_GET_DONE = "PRODUCT_LIST_GET_DONE";
export const PRODUCT_LIST_GET_FAIL = "PRODUCT_LIST_GET_FAIL";

export const PRODUCT_UPDATE_DONE = "PRODUCT_UPDATE_DONE";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";

export const CHANGE_STATUS_DONE = "CHANGE_STATUS_DONE";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const ADD_TO_GRUOP_DONE = "ADD_TO_GRUOP_DONE";
export const ADD_TO_GRUOP_FAIL = "ADD_TO_GRUOP_FAIL";


export const PRODUCT_BATCH_LIST_GET_DONE = "PRODUCT_BATCH_LIST_GET_DONE";
export const PRODUCT_BATCH_LIST_GET_FAIL = "PRODUCT_BATCH_LIST_GET_FAIL";

export const PRODUCT_BATCH_BULK_UPLOAD_DONE = "PRODUCT_BATCH_BULK_UPLOAD_DONE";
export const PRODUCT_BATCH_BULK_UPLOAD_FAIL = "PRODUCT_BATCH_BULK_UPLOAD_FAIL";

export const PRODUCT_BULK_IMPORT_DONE = "PRODUCT_BULK_IMPORT_DONE";
export const PRODUCT_BULK_IMPORT_FAIL = "PRODUCT_BULK_IMPORT_DONE";

export const CREATE_PRODUCT_API_URL = `${APP_API_URL}/product/create`;
export const UPDATE_PRODUCT_API_URL = `${APP_API_URL}/product/update`;

export const GET_PRODUCT_CODE_DONE = "GET_PRODUCT_CODE_DONE";
export const GET_PRODUCT_CODE_FAIL = "GET_PRODUCT_CODE_FAIL";

export const GRUOP_BULK_UPLOAD_DONE = "GRUOP_BULK_UPLOAD_DONE";
export const GRUOP_BULK_UPLOAD_FAIL = "GRUOP_BULK_UPLOAD_FAIL";

export const LIST_PRODUCT_API_URL = `${APP_API_URL}/product/getAllProducts`;
export const LIST_PRODUCT_BATCH_API_URL = `${APP_API_URL}/product/product-batch`;
export const ADD_PRODUCT_TO_GROUP_API_URL = `${APP_API_URL}/product/add-to-group`;
export const CHANGE_STATUS_API_URL = `${APP_API_URL}/product/update-status`;
export const CREATE_PRODUCT_BULK_API_URL = `${APP_API_URL}/product/import-product`;
export const CREATE_BATCH_BULK_API_URL = `${APP_API_URL}/product/import-product-batch`;
export const PRODUCT_CODE_API_URL = `${APP_API_URL}/product/get-product-code`;
export const GROUP_BULK_API_URL = `${APP_API_URL}/product/import-product-group`;

let token = getLocalStorageData()
const configFormdata = {
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token?.token}`,
    },
}

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}
export const product_create = (formBody) => {
    let API_URL = CREATE_PRODUCT_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, configFormdata).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: PRODUCT_CREATE_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: PRODUCT_CREATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};
export const product_list = (param) => {
    let API_URL = LIST_PRODUCT_API_URL;
    if (param) {
        API_URL = `${LIST_PRODUCT_API_URL}?${param}`;
    }
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: PRODUCT_LIST_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: PRODUCT_LIST_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};
export const product_batch = () => {
    let API_URL = LIST_PRODUCT_BATCH_API_URL;
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: PRODUCT_BATCH_LIST_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: PRODUCT_BATCH_LIST_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};

export const add_product_to_group = (formBody) => {
    let API_URL = ADD_PRODUCT_TO_GROUP_API_URL;
    return (dispatch) => {
        return axios.put(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: ADD_TO_GRUOP_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: ADD_TO_GRUOP_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};
export const change_status = (formBody) => {
    let API_URL = CHANGE_STATUS_API_URL;
    return (dispatch) => {
        return axios.put(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: CHANGE_STATUS_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: CHANGE_STATUS_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};

export const product_Update = (id, formBody) => {
    let API_URL = `${UPDATE_PRODUCT_API_URL}/${id}`;
    return (dispatch) => {
        return axios.put(API_URL, formBody, configFormdata).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: PRODUCT_UPDATE_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: PRODUCT_UPDATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};

export const product_create_bulk = (formBody) => {
    let API_URL = CREATE_PRODUCT_BULK_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, configFormdata).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: PRODUCT_BULK_IMPORT_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: PRODUCT_BULK_IMPORT_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};


export const batch_create_bulk = (id, formBody) => {
    let API_URL = `${CREATE_BATCH_BULK_API_URL}/${id}`;
    return (dispatch) => {
        return axios.post(API_URL, formBody, configFormdata).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: PRODUCT_BATCH_BULK_UPLOAD_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: PRODUCT_BATCH_BULK_UPLOAD_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};
export const product_code_get = (param) => {
    let API_URL = PRODUCT_CODE_API_URL;
    if (param) {
        API_URL = `${PRODUCT_CODE_API_URL}?${param}`;
    }
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: GET_PRODUCT_CODE_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: GET_PRODUCT_CODE_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};
export const Group_bulk_import = (formBody) => {
    let API_URL = `${GROUP_BULK_API_URL}`;
    return (dispatch) => {
        return axios.post(API_URL, formBody, configFormdata).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: GRUOP_BULK_UPLOAD_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: GRUOP_BULK_UPLOAD_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};