import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import { useDispatch } from "react-redux";
import { BulkUploadSampleSheet } from "../../../store/action/utility";
import { toast } from "react-toastify";
import {
  batch_create_bulk,
  Group_bulk_import,
  product_create_bulk,
} from "../../../store/action/product";
import { ExportTable } from "../../../store/action/export/Export";

const BulkImport = ({ onClose, reloadTable, type, productID }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleDownload = () => {
    dispatch(BulkUploadSampleSheet()).then((res) => {
      if (res?.success) {
        if (type === "product") {
          setDownloadLink(res?.data?.productSheet);
        } else if (type === "batch") {
          setDownloadLink(res?.data?.productBatchSheet);
        } else if (type === "group") {
          setDownloadLink(res?.data?.productGroupSheet);
        }
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    handleDownload();
  }, []);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["xlsx", "xls", "csv"];

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFile(file);
      setErrorMessage("");
      // console.log('Selected file:', file);
    } else {
      setSelectedFile(null);
      setErrorMessage("Please upload a valid Excel or CSV file.");
      // console.log('Invalid file type:', file);
    }
  };

  const handleSave = () => {
    setLoader(true);
    if (selectedFile) {
      // console.log('Saving file:', selectedFile);
      let formData = new FormData();
      formData.append("file", selectedFile);
      if (type === "product") {
        dispatch(product_create_bulk(formData)).then((res) => {
          if (res?.success) {
            toast.success(res?.message);
            // navigate(-1)
            setLoader(false);
          } else {
            toast.error(res?.message);
          }
          reloadTable();
          onClose();
        });
        setLoader(false);
      }
      if (type === "batch") {
        dispatch(batch_create_bulk(productID, formData)).then((res) => {
          if (res?.success) {
            toast.success(res?.message);
            // navigate(-1)
            setLoader(false);
          } else {
            toast.error(res?.message);
            setLoader(false);
          }
          reloadTable();
          onClose();
        });
        setLoader(false);
      }
      if (type === "group") {
        dispatch(Group_bulk_import(productID, formData)).then((res) => {
          if (res?.success) {
            toast.success(res?.message);
            reloadTable();
            setLoader(false);
          } else {
            toast.error(res?.message);
            setLoader(false);
          }
          reloadTable();
          onClose();
        });
        setLoader(false);
      }
    } else {
      toast.error("No file selected or invalid file type");
      setLoader(false);
    }
  };
  const DownlaodexcelData = (param) => {
    // console.log(param);
    ExportTable(param);
  };
  return (
    <div className="">
      <div className="d-flex justify-content-end mb-3 flex-coloum">
        <Link to={downloadLink}>
          {`Download Sample ${type} file for bulk import`}
        </Link>
      </div>
      {type === "product" && (
        <div className="d-flex justify-content-end mb-3 flex-coloum">
          <Link onClick={() => DownlaodexcelData("product_group")}>
            Download group excel file
          </Link>
        </div>
      )}
      {type === "product" && (
        <div className="d-flex justify-content-end mb-3 flex-coloum">
          <Link to={""} onClick={() => DownlaodexcelData("product_category")}>
            Download catogery excel file
          </Link>
        </div>
      )}
      <div>
        <div className="add-productgroup-form">
          <label className="form-label" htmlFor={`file`}>
            {"Upload a file"}
            {<span style={{ color: "red" }}>*</span>}
          </label>
          <input
            className={`form-control mb-lg-4 mb-md-3 mb-sm-3 mb-3 inpuBG `}
            type="file"
            label="Upload a file"
            placeholder="Select Group"
            onChange={handleFileChange}
            name="file"
          />
          {errorMessage && (
            <div className="text-danger mt-2">{errorMessage}</div>
          )}
        </div>
        <div className="add-productgroup-footer d-flex justify-content-between">
          <Button
            type="button"
            button="Save"
            className="btn-primary add-pgroup"
            onClick={handleSave}
            disabled={!selectedFile}
            loader={loader}
          />
          <Button
            type="button"
            button="Cancel"
            className="btn-dark add-pgroup"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default BulkImport;
