import React from 'react'
import UnderCunstruction from '../underCunstruction'
import './dashboardstyle.scss';

const Dashboard = () => {
    return (
        <div className='dashboard-container container'>
            {/* <!-- First Row with 2 Columns --> */}
            <div className="row row-space frist-row">
                <div className="col-md-6 ">Column 1</div>
                <div className="col-md-6 ">Column 2</div>
            </div>

            {/* <!-- Second Row with 4 Columns --> */}
            <div className="row row-space second-row">
                <div className="col-md-3 ">Card 1</div>
                <div className="col-md-3 ">Card 2</div>
                <div className="col-md-3 ">Card 3</div>
                <div className="col-md-3 ">Card 4</div>
            </div>

            {/* <!-- Third Row with 2 Columns --> */}
            <div className="row row-space third-row">
                <div className="col-md-6 ">Pie chart
                    <div className="row row-space ">
                        <div className="col-md-3 ">circle 1</div>
                        <div className="col-md-3 ">circle 2</div> 
                        <div className="col-md-3 ">circle 3</div>
                    </div>
                </div>
                <div className="col-md-6 ">Column 2</div>
            </div>

            {/* <!-- Fourth Row with 2 Columns --> */}
            <div className="row row-space fourth-row">
                <div className="col-md-6 ">Column 1</div>
                <div className="col-md-6 ">Column 2</div>
            </div>

        </div>
    )
}

export default Dashboard



