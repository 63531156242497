

import { createBrowserRouter, Outlet } from "react-router-dom";
import Login from "../pages/auth/login/Login";
import ForgotPassword from "../pages/auth/forgotpassword/ForgotPassword";
import Signup from "../pages/auth/signup/Signup";
import CreateNewPassword from "../pages/auth/createNewPassword/CreateNewPassword";
import OTPVerify from "../pages/auth/otpVerify/OTPVerify";
import Layout from "../layout/Layout";
import Dashboard from "../pages/dashboard";
import OrganizationDetail from "../pages/organizationDetail/OrganizationDetail";
import PrivateRoute from "./PrivateRoutes";
import ProductDetails from "../pages/inventory/product";
import ProductGroup from "../pages/inventory/product/productGroup/ProductGroup";
import Adjustment from "../pages/inventory/adjustment";
import InventoryAdjustment from "../pages/inventory/adjustment/InventoryAdjustment";
import NotFound from "../pages/404NotFound";
import CreateProduct from "../pages/inventory/product/addProduct/CreateProduct";
import GroupDetails from "../pages/inventory/product/productGroup/GroupDetails";


export const Approuter = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: `/forgotPassword`,
    element: <ForgotPassword />
  },
  {
    path: "/signup",
    element: <Signup />
  },
  {
    path: "/createNewPassword",
    element: <CreateNewPassword />
  },
  {
    path: "/otpVerify/*",
    element: <OTPVerify />
  },
  {
    path: "/organizationDetail",
    element: <OrganizationDetail />
  },
  {
    path: "/user",
    element: <Layout />,
    children: [
      {
        path: `/user/:suburl?/:suburl?/home`,
        element: <PrivateRoute element={<Dashboard />} />
      },
      {
        path: "/user/:suburl?/product/:id?",
        element: <PrivateRoute element={<ProductDetails />} />
      },
      {
        path: "/user/:suburl?/product-create/:id?",
        element: <PrivateRoute element={<CreateProduct />} />
      },
      {
        path: "/user/:suburl?/group",
        element: <PrivateRoute element={<ProductGroup />} />
      },
      {
        path: "/user/:suburl?/group-details/:id",
        element: <PrivateRoute element={<GroupDetails />} />
      },
      {
        path: "/user/:suburl?/inventory-adjustment-crete/:id?",
        element: <PrivateRoute element={<Adjustment />} />
      },
      {
        path: "/user/:suburl?/inventory-adjustment",
        element: <PrivateRoute element={<InventoryAdjustment />} />
      },
      // Add a catch-all route for 404 page
      {
        path: "*",
        element: <NotFound />
      }
    ]
  },
]);



