import React, { useEffect, useState } from "react";
import "./OrganizationDetail.scss";
import InputField from "../../component/form/FormInput";
import FormSelect from "../../component/form/FormSelect";
import Button from "../../component/ui/Button";
import { useNavigate } from "react-router-dom";
import {
  country_dropdown,
  curency_dropdown,
  indianStates,
  industry_dropdown,
  language_dropdown,
  physical_year_dropdown,
  timeZone_dropdown,
} from "../../utils/dropsdown";
import { useDispatch, useSelector } from "react-redux";
import { master_data } from "../../store/action/master";
import { formatErrors, objectCreater } from "../../utils/controller";
import { organistation_setup } from "../../store/action/organistation";
import { toast } from "react-toastify";
import logo from "../../assets/image/PharmaNex-logo.svg";
import logout from "../../assets/image/logout.svg";
import { fetchUserInfo } from "../../store/action/userInfo";
import Cookies from "js-cookie";

const OrganizationDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const suburl = 'Hello'
  const suburl = localStorage.getItem("suburl");
  const userDetails = useSelector((state) => state?.userInfo?.userInfo?.data);
  const [organizationName, setorganizationName] = useState(
    userDetails?.organization_name
  );
  const [industry, setIndustry] = useState("");
  const [organizationCountry, setorganizationCountry] = useState();
  const [state, setState] = useState("");
  const [street1, setstreet1] = useState("");
  const [street2, setstreet2] = useState("");
  const [city, setcity] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [subDomain, setsubDomain] = useState("");

  const [curency, setCurency] = useState("INR");
  const [language, setlanguage] = useState("english");
  const [year, setYear] = useState("April-March");
  const [timeZone, setTimeZone] = useState(
    "(UTC+05:30) Chennai,Kolkata,Mumbai,New Delhi"
  );
  const [inventoryStartDate, setinventoryStartDate] = useState();

  const [error, setError] = useState({});
  let masterData = useSelector((state) => state?.master?.masterData?.data);
  const countryList = objectCreater(masterData?.countries);
  const StateList = objectCreater(masterData?.states);
  const industryList = objectCreater(masterData?.industry);
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000);
  useEffect(() => {
    userInfoDetails();
  }, []);
  // console.log('userDetails',userDetails)
  useEffect(() => {
    setorganizationName(userDetails?.userData?.organization_name);
    setorganizationCountry(userDetails?.userData?.address?.country_id);
    setIndustry(1);
    setState(userDetails?.userData?.address?.state_id);
    // setsubDomain(
    //   userDetails?.userData?.organization_name
    //     ?.toLowerCase()
    //     ?.split(/[\s\W]+/)?.[0]
    // );
  }, [userDetails, masterData]);
  useEffect(() => {
    dispatch(master_data());
  }, []);
  useEffect(() => {
    setsubDomain(organizationName?.toLowerCase()?.split(/[\s\W]+/)?.[0]);
  }, [organizationName]);
  const handleOrganistionDetails = () => {
    let body = {
      organization_name: organizationName, // organisation name here same as u put in user signup
      industry_id: Number(industry),
      country_id: Number(organizationCountry),
      state_id: Number(state),
      street_1: street1,
      street_2: street2,
      city: city,
      postal_code: zipCode,
      sub_domain: subDomain.toLowerCase().trim(),
      currency: curency,
      language: language,
      time_zone: timeZone,
      inventory_start_date: inventoryStartDate,
      fiscal_year: year,
    };
    dispatch(organistation_setup(body)).then((res) => {
      if (res?.success) {
        localStorage.setItem("suburl", res?.data?.sub_domain);
        // console.log('res', res)
        // localStorage.setItem('authToken', res?.token)
        Cookies.set("authToken", res?.token, {
          expires: expirationDate,
          secure: true,
          sameSite: "Strict",
        });
        toast.success(res?.message);
        setTimeout(() => {
          window.location.reload();
        }, 500);
        navigate(`/user/${subDomain}/home`);
      } else {
        let errors = formatErrors(res?.message);
        // console.log(errors)
        setError(errors);
        toast.error(res?.response?.data?.message);
      }
    });
  };

  const handleBackto = () => {
    localStorage.clear();
    navigate("/");
    Cookies.remove("authToken");
  };

  const userInfoDetails = () => {
    dispatch(fetchUserInfo()).then((res) => {
      if (res?.success) {
        // console.log(res?.data?.sub_domain)
        localStorage.setItem("suburl", res?.data?.sub_domain);
      } else {
        // console.log(res?.data)
      }
    });
  };
  // console.log(error)
  return (
    <div>
      <div className="navtop ms-0 w-100" style={{ zIndex: "999" }}>
        <div className="nav-container">
          <div className="input-group form-mobile-check left-search w-auto">
            <img src={logo} alt="mdo" width="130" height="" />
          </div>
          <div className="font-medium" style={{ fontSize: "1.6rem" }}>
            Setup your Organisationprofile
          </div>
          <div className="right-details">
            <span className="welcomeText">
              Hey!{" "}
              <span className="currentName">
                Welcome! {userDetails?.first_name}
              </span>
            </span>
            <img
              src={logout}
              alt="mdo"
              width=""
              height="20"
              style={{ cursor: "pointer" }}
              onClick={handleBackto}
            />
            {/* <img src={notification} alt="mdo" width="25" height="25" /> */}
          </div>
        </div>
      </div>
      <div className="organizationDetail-form">
        <div className="organizationDetail-body clearfix">
          <div className="right-section  position-relative">
            <form className="form-body">
              <div className="d-flex justify-content-between align-items-baseline pb-4">
                <h1 className="form-title pb-0">Organisation Detail</h1>
                <span className="back-button " onClick={handleBackto}>
                  <i className="fa fa-arrow-left"></i>
                  Back
                </span>
              </div>

              <div className="row">
                <div className="col-6">
                  <InputField
                    type="text"
                    placeholder="Enter your Organisation/Company name"
                    label="Organisation Name"
                    onChange={(value) => setorganizationName(value)}
                    value={organizationName}
                    name={"organization_name"}
                    error={error?.organization_name}
                    autoFocus={true}
                    // disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormSelect
                    label="Industry"
                    placeholder="Information Technology"
                    onChange={(value) => setIndustry(value)}
                    value={industry}
                    options={industryList}
                    name={"industry_id"}
                    error={error?.industry}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormSelect
                    label="Organisation Country"
                    placeholder="India"
                    onChange={(value) => setorganizationCountry(value)}
                    value={organizationCountry}
                    options={countryList}
                    disabled={true}
                    name={"country_id"}
                    error={error?.country_id}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormSelect
                    label="State/Union Territory"
                    placeholder="Select State"
                    onChange={(value) => setState(value)}
                    value={state}
                    options={StateList}
                    name={"state_id"}
                    error={error?.state ? "is not allowed to be empty." : ""}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    type="text"
                    placeholder="Street 1"
                    label="Street1"
                    onChange={(value) => setstreet1(value)}
                    value={street1}
                    name={"street_1"}
                    error={error?.street_1}
                    // required={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    type="text"
                    value={street2}
                    placeholder="Street 2"
                    label="Street2"
                    onChange={(value) => setstreet2(value)}
                    name={"street_2"}
                    error={error?.street_2}
                    // required={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    type="text"
                    value={city}
                    placeholder="India"
                    label="City"
                    onChange={(value) => setcity(value)}
                    name={"city"}
                    error={error?.city}
                    // required={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    type="text"
                    value={zipCode}
                    placeholder="ZIP/Postal Code"
                    label="ZIP/Postal Code"
                    onChange={(value) => setzipCode(value)}
                    name={"postal_code"}
                    error={error?.postal_code}
                    // required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Setup organization URL</label>
                  <span style={{ color: "red" }}>*</span>
                  <div className="input-group mb-3">
                    <div className="input-group-append">
                      <span
                        className="input-group-text font-medium"
                        style={{
                          minHeight: "4rem",
                          fontSize: "1.4rem",
                          marginLeft: ".1rem",
                          minWidth: "5rem",
                          fontSize: "1.4rem",
                          justifyContent: "center",
                          color: "var(--brand-color)",
                          borderRadius: " 8px 0 0 8px",
                        }}
                      >
                        www.app.pharmnex.app/
                      </span>
                    </div>
                    <input
                      style={{
                        borderRadius: "8px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        border: "1px solid rgba(85, 91, 103, 0.15)",
                        backgroundColor: "#FCFCFC",
                        padding: "8px",
                        borderLeft: "0",
                      }}
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="thewaltdesinycompany"
                      onChange={(e) => setsubDomain(e?.target?.value)}
                      value={subDomain}
                      label="Create your sub domain"
                      name={"sub_domain"}
                      error={error?.sub_domain}
                    />
                  </div>
                </div>
                {error && (
                  <div
                    className="error-text"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "10px",
                    }}
                  >
                    {error?.sub_domain}
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-baseline pb-4">
                <h1 className="form-title pb-0">Regional Details</h1>
              </div>
              <div className="row">
                <div className="col-6">
                  <FormSelect
                    label="Currency"
                    placeholder="INR - Indian Rupee"
                    onChange={(value) => setCurency(value)}
                    value={curency}
                    options={curency_dropdown}
                    name={"currency"}
                    error={error?.curency}
                    className="inputDarkBG"
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormSelect
                    label="Language"
                    placeholder="English"
                    onChange={(value) => setlanguage(value)}
                    value={language}
                    options={language_dropdown}
                    name={"language"}
                    error={error?.language}
                    className="inputDarkBG"
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <FormSelect
                    label="Time Zone"
                    placeholder="(GMT 5:30) India Standard Time (Asia/Calcutta)"
                    onChange={(value) => setTimeZone(value)}
                    value={timeZone}
                    options={timeZone_dropdown}
                    name={"time_zone"}
                    error={error?.time_zone}
                    className="inputDarkBG"
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    type="Date"
                    placeholder="303705"
                    label="Inventory Start Date"
                    onChange={(value) => setinventoryStartDate(value)}
                    value={inventoryStartDate}
                    name={"inventory_start_date"}
                    error={error?.inventory_start_date}
                    required={true}
                  />
                </div>
                <div className="col-6">
                  <FormSelect
                    label="Fiscal Year"
                    placeholder="April-March"
                    onChange={(value) => setYear(value)}
                    value={year}
                    options={physical_year_dropdown}
                    name={"fiscal_year"}
                    error={error?.fiscal_year}
                    className="inputDarkBG"
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <p className="">
                    <span className="font-bold me-1">Notes:</span>You can update
                    some of these preference from Settings anytime.
                  </p>
                </div>
              </div>
              <div className="col-4 m-auto mt-4">
                <span className="">
                  <Button
                    type="button"
                    className="btn-primary"
                    button="Get Started"
                    onClick={handleOrganistionDetails}
                  ></Button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetail;
