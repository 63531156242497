import React, { useEffect, useRef, useState } from "react";
import './Signup.scss';
import signup from '../../../assets/image/signup_illustration.svg';
import InputField from "../../../component/form/FormInput";
import Button from "../../../component/ui/Button";
import { toast } from "react-toastify";
import FormSelect from "../../../component/form/FormSelect";
import { useNavigate } from "react-router-dom";
import { copyToClipboard, encodeEmailToToken, formatErrors, generateStrongPassword, objectCreater } from "../../../utils/controller";
import { number_prefix_dropdown, passwordPolicy } from "../../../utils/dropsdown";
import { master_data } from "../../../store/action/master";
import { useDispatch, useSelector } from "react-redux";
import { user_send_otp, user_signup } from "../../../store/action/auth";
import { Helmet } from 'react-helmet';
import flag from '../../../assets/image/flag.png';
import PasswordPolicy from "../../../component/PasswordPloicy";
import ModalRight from "../../../component/modalRight";
import InputSelect from "../../../component/inputSelect/InputSelect";
import { ObjectCreate } from "../../../utils/utility";
import logo from '../../../assets/image/onboarding-logo.png'
import Cookies from 'js-cookie';

const Signup = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState({});
  const [checkTerms, setCheckTerms] = useState(false);
  const [openPassPolicy, setOpenPassPolicy] = useState(false);
  let masterData = useSelector(state => state?.master?.masterData?.data);
  // console.log('masterData', masterData);
  const countryList = objectCreater(masterData?.countries);
  const StateList = objectCreater(masterData?.states);
  const firstNameRef = useRef(null);
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + (12 * 60 * 60 * 1000));
  // Load form data from session storage on component mount
  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem('signupFormData'));
    if (storedData) {
      setEmail(storedData.email);
      setFirstName(storedData.firstName);
      setLastName(storedData.lastName);
      setOrganisation(storedData.organisation);
      setPhone(storedData.phone);
      setCountry(storedData.country);
      setState(storedData.state);
      setCheckTerms(storedData.checkTerms);
    }
  }, []);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

 

  // master API call
  useEffect(() => {
    dispatch(master_data());
  }, [dispatch]);

  const handleSignup = () => {
    setLoader(true);

    // Save form data to session storage
    const formData = {
      email,
      firstName,
      lastName,
      organisation,
      phone,
      country,
      state,
      checkTerms,
    };
    sessionStorage.setItem('signupFormData', JSON.stringify(formData));

    let body = {
      first_name: firstName,
      last_name: lastName,
      organization_name: organisation,
      email: email,
      password: password,
      country_id: Number(country),
      state_id: Number(state),
      phone_number: phone,
    };

    if (checkTerms) {
      dispatch(user_signup(body)).then((res) => {
        if (res?.success) {
          // localStorage.setItem('authToken', res?.token);
          Cookies.set('authToken', res?.token, { expires: expirationDate, secure: true, sameSite: 'Strict' });
          if (res?.data?.is_email_verified === false) {
            const token = encodeEmailToToken(res?.data?.email);
            dispatch(user_send_otp({ email: res?.data?.email })).then((res) => {
              if (res?.success) {
                setLoader(false);
                toast.success(res?.message);
                navigate(`/otpVerify/?token=${token}&page_signup=true`);
              } else {
                toast.error(res?.message);
                // console.log(res?.message);
              }
            });
          }
        } else {
          toast.error(res?.message);
          let errors = formatErrors(res?.message);
          setError(errors);
          // console.log(errors);
          setLoader(false);
        }
      });
    } else {
      toast.error('Please agree to the Terms of Services and Privacy Policy');
      setLoader(false);
    }
  };

  const handleGeneratePasswprd = () => {
    let generated_password = generateStrongPassword();
    setPassword(generated_password);
    setIsVisible(!isVisible);
  };

  const handleBackto = () => {
    navigate("/");
  };

  const handleChange = (e) => {
    let phoneNumber = e?.target?.value;
    if (phoneNumber.length === 11 && phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.slice(1);
      setPhone(phoneNumber); // Remove the leading '0'
    } else {
      setPhone(phoneNumber);
    }
  };

  return (
    <div className='signup-form'>
      <Helmet>
        <title>Create your Free PharmNex account</title>
      </Helmet>
      <div className="signup-body row clearfix">
        <div className="left-section">
          <div className="illustration">
            {/* <div className="brand-name"><span>Pharm</span><span>Nex</span></div> */}
            <div className="brand-name"><img src={logo} alt="" /></div>
            <p className="text-subtitle mb-5">
              <div className="text-center">Unlock the full potential of our innovation</div>
              <div className="text-center">Sign up now for exclusive access to our <span className="font-bold">pre-release version</span> </div>
            </p>
            <div className="signup-illustration"><img src={signup} alt="" /></div>
          </div>
        </div>
        <div className="right-section">
          <span className="back-button" onClick={handleBackto} title="Back To Sign In">
            <i className="fa fa-arrow-left"></i>
            Back
          </span>
          <form className="form-body py-4 ms-0">
            <div className="d-flex justify-content-between align-items-baseline pb-4">
              <h1 className="form-title pb-0">Sign Up</h1>
              <p className="">Already have an account? <span className="primary-color cursor-pointer"><a href="/" className="font-bold"> Sign In</a></span></p>
            </div>
            <div className="row">
              <div className="col-6">
                <InputField
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  label="First Name"
                  onChange={(value) => setFirstName(value)}
                  error={error?.first_name}
                  required
                  autoFocus={true}
                  autoComplete={'First Name'}
                  name="First Name"
                />
              </div>
              <div className="col-6">
                <InputField
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  label="Last Name"
                  onChange={(value) => setLastName(value)}
                  error={error?.last_name}
                  required
                  autoComplete={'Last Name'}
                  name="Last Name"
                />
              </div>
              <div className="col-12">
                <InputField
                  type="text"
                  value={organisation}
                  placeholder="Enter your Organisation/Company name"
                  label="Organisation Name"
                  onChange={(value) => setOrganisation(value)}
                  error={error?.organization_name}
                  required
                  autoComplete={'Organisation Name'}
                  name="Organisation Name"
                />
              </div>
              <div className="col-12">
                <InputField
                  type="text"
                  value={email}
                  placeholder="Email Address"
                  label="Email/Username"
                  onChange={(value) => setEmail(value)}
                  error={error?.email}
                  required
                  autoComplete={'email'}
                  name="email"
                />
              </div>
              <div className="col-12 position-relative">
                <InputField
                  type="password"
                  value={password}
                  placeholder="Create new password"
                  label="Password"
                  onChange={(value) => setPassword(value)}
                  error={error?.password}
                  required
                  autoComplete="new-password"
                  showPasswordIcon
                  onClick={() => setOpenPassPolicy(true)}
                />
                {isVisible ? <span className="auto-genrated curser-pointer"><a className="font-bold text-decoration-none curser-pointer" onClick={() => copyToClipboard(password)}>Copy</a></span>
                  :
                  <span className="auto-genrated curser-pointer"><a className="font-bold text-decoration-none curser-pointer" onClick={handleGeneratePasswprd}>Auto-Generate</a></span>}
                {password && <PasswordPolicy password={password} />}
              </div>
              <div className="col-6">
                <FormSelect
                  label="Country"
                  placeholder="Country"
                  options={countryList}
                  onChange={(value) => setCountry(value)}
                  value={country}
                  error={error?.country}
                  required
                  name={'country'}
                />
              </div>
              <div className="col-6">
                <FormSelect
                  label="State"
                  placeholder="State"
                  options={StateList}
                  onChange={(value) => setState(value)}
                  value={state}
                  error={error?.state}
                  required
                  name={'state'}
                />
              </div>
              <div className="col-12 form-select-mobile">
                <label className="form-label">Mobile Number</label><span style={{ color: 'red' }}>*</span>
                <div className="input-group mb-4 form-mobile-check">
                  <div className="input-group-prepend">
                    <div className="input-group-text p-0" style={{
                      minHeight: '4rem',
                      fontSize: '1.4rem',
                      marginRight: ".1rem",
                      minWidth: "5rem", fontSize: "1.4rem", borderTopRightRadius: '0', borderBottomRightRadius: "0", justifyContent: "center"
                    }}>
                      <img className="ms-3" src={flag} alt="flag" />
                      <span className="mx-3">+91</span>
                    </div>
                  </div>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    style={{ borderLeft: '0' }}
                    placeholder="Enter mobile number"
                    value={phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" onChange={(e) => setCheckTerms(e?.target?.checked)} value={checkTerms} />
                  <p htmlFor="inlineCheckbox1" className="mb-4 form-check-label">I agree to the <span><a href="/signup" className="font-bold"> Terms of Services</a> and <a href="/signup" className="font-bold"> Privacy Policy</a></span></p>
                </div>
              </div>
              <div className="col-12">
                <Button type="button" className="btn-primary" button="Create Your Account" onClick={handleSignup} loader={loader}></Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {openPassPolicy && (
        <div className=''>
          <ModalRight show={openPassPolicy} isOpen={openPassPolicy} onClose={() => setOpenPassPolicy(false)} title="Password Policy"
            description1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since."
            description2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
            description3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
            linkTitle='Learn More'
            linkPath1='#' linkName1='Lorem Ipsum is simply dummy text of the printing'
            linkPath2='#' linkName2='Lorem Ipsum is simply dummy text of the printing'
            linkPath3='#' linkName3='Lorem Ipsum is simply dummy text of the printing'
            linkPath4='#' linkName4='Lorem Ipsum is simply dummy text of the printing'
            linkPath5='#' linkName5='Lorem Ipsum is simply dummy text of the printing'
            linkPath6='#' linkName6='Lorem Ipsum is simply dummy text of the printing'
          ></ModalRight>
        </div>
      )}
    </div>
  );
}

export default Signup;
