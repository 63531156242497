import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

export const GROUP_CREATE_DONE = "GROUP_CREATE_DONE";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_DONE";

export const GROUP_UPDATE_DONE = "GROUP_UPDATE_DONE";
export const GROUP_UPDATE_FAIL = "GROUP_UPDATE_UPDAT";

export const GROUP_LIST_GET_DONE = "GROUP_LIST_GET_DONE";
export const GROUP_LIST_GET_FAIL = "GROUP_LIST_GET_DONE";


export const CREATE_GROUP_API_URL = `${APP_API_URL}/product-group/create`;
export const UPDATE_GROUP_API_URL = `${APP_API_URL}/product-group/update`;
export const LIST_GROUP_API_URL = `${APP_API_URL}/product-group/list`;

let token = getLocalStorageData()
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}

export const group_create = (formBody) => {
    let API_URL = CREATE_GROUP_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: GROUP_CREATE_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: GROUP_CREATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};
export const product_group_list = (param) => {
    let API_URL = `${LIST_GROUP_API_URL}?${param}`;
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: GROUP_LIST_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: GROUP_LIST_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};

export const group_update = (id, formBody) => {
    let API_URL = UPDATE_GROUP_API_URL;
    if (id) {
        API_URL = `${API_URL}/${id}`
    }
    return (dispatch) => {
        return axios.put(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: GROUP_UPDATE_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: GROUP_UPDATE_DONE,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};