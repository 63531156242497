import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

export const ORGANISTATION_SETUP_DONE = "ORGANISTATION_SETUP_DONE";
export const ORGANISTATION_SETUP_FAIL = "ORGANISTATION_SETUP_DONE";


export const ORGANISTATION_API_URL = `${APP_API_URL}/auth/organisation-setup`;

let token = getLocalStorageData()
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}

export const organistation_setup = (formBody) => {
    let API_URL = ORGANISTATION_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: ORGANISTATION_SETUP_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: ORGANISTATION_SETUP_FAIL,
                    payload: res,
                });
                return res?.data;
            }
        }).catch((err) => {
            return err
        });
    };
};