import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

const FormSelect = ({ 
  label, 
  placeholder, 
  options, 
  onChange, 
  disabled, 
  name, 
  error, 
  autoFocus, 
  className, 
  infoIcon, 
  required, 
  value, 
  onClick 
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const selectRef = useRef();
  const location = useLocation();
  const pathName = location?.pathname;

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue);
    selectRef.current.focus(); // Ensure focus remains after selection
  };

  useEffect(() => {
    if (autoFocus && selectRef.current) {
      selectRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const toggleInfoIcon = () => {
    setIsPopupVisible(!isPopupVisible);
    if (onClick) {
      onClick(!isPopupVisible); // Pass the new visibility state to the onClick callback
    }
  };

  return (
    <div className="form-group position-relative mb-lg-4 mb-md-3 mb-sm-3 mb-3">
      {label && <label className="form-label" htmlFor={name}>{label}</label>}
      {required && <span style={{ color: 'red' }}>*</span>}
      {infoIcon &&
        <i data-tooltip-id="my-tooltip-1" onClick={toggleInfoIcon} className="fa fa-info-circle" style={{ cursor: 'pointer', fontSize: '1.4rem', color: 'var(--brand-color)', marginLeft: '.2rem' }}></i>
      }
      <div style={{ position: 'relative', border: error ? '1px solid #f71326' : '', borderRadius: '6px' }}>
        <select
          ref={selectRef}
          value={selectedOption}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          autoFocus={autoFocus}
          className={`form-control ${className}`}
          style={{ paddingRight: '30px' }} // Adjust padding to make space for the arrow icon
        >
          <option value="" disabled>{placeholder}</option>
          {options && options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <i className="fa fa-caret-down" style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
      </div>
      {error && <div className="error-text" style={{ color: 'red', fontSize: "12px", marginBottom: "10px" }}>{error}</div>}
    </div>
  );
};

export default FormSelect;
