import React from 'react'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import './Layout.scss';
import UnderCunstruction from '../pages/underCunstruction';
import { Outlet } from 'react-router-dom';

const Layout = ()=> {
  return (
    <div>
      <div>
      <Navbar/>
      <div className='d-flex'>
      <Sidebar/>
      <div className='layout-wrapper'>
        {/* <UnderCunstruction prop = {'Page'}/> */}
        <Outlet/>
      </div>
      </div>
      
      
      
     
      </div>
    </div>
  )
}
export default Layout;