import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

 

export const GLOBAL_SEARCH_GET_DONE = "GLOBAL_SEARCH_GET_DONE";
export const GLOBAL_SEARCH_GET_FAIL = "GLOBAL_SEARCH_GET_DONE";

 
export const GLOBAL_SEARCH_API_URL = `${APP_API_URL}/app/globalSearch`;

let token = getLocalStorageData()
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}

 
export const globalSearch = (param) => {
    let API_URL = `${GLOBAL_SEARCH_API_URL}?${param}`;
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: GLOBAL_SEARCH_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: GLOBAL_SEARCH_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};

 