import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

export const CATOGERY_CREATE_DONE = " CATOGERY_CREATE_DONE";
export const CATOGERY_CREATE_FAIL = " CATOGERY_CREATE_DONE";

export const CATOGERY_LIST_GET_DONE = " CATOGERY_LIST_GET_DONE";
export const CATOGERY_LIST_GET_FAIL = " CATOGERY_LIST_GET_DONE";


export const CREATE_CATOGERY_API_URL = `${APP_API_URL}/product-category/create`;
export const LIST_CATOGERY_API_URL = `${APP_API_URL}/product-category/list`;

let token = getLocalStorageData()
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
}

export const catogery_create = (formBody) => {
    let API_URL = CREATE_CATOGERY_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: CATOGERY_CREATE_DONE,
                    payload: res,
                });
                return res?.data;
            } else {
                dispatch({
                    type: CATOGERY_CREATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            return err
        });
    };
};
export const product_catogery_list = (param) => {
    let API_URL = LIST_CATOGERY_API_URL;
    if (param) {
        API_URL = `${LIST_CATOGERY_API_URL}?${param}`
    }
    return (dispatch) => {
        return axios
            .get(API_URL, config).then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: CATOGERY_LIST_GET_DONE,
                        payload: res,
                    });
                    return res?.data;
                } else {
                    dispatch({
                        type: CATOGERY_LIST_GET_FAIL,
                        payload: res,
                    });
                    return res;
                }
            }).catch((err) => {
                return err
            });
    };
};