export const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Ladakh', label: 'Ladakh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' }
];
export const country_dropdown = [
    { value: "India", label: "India" }
]

export const curency_dropdown = [
    { value: "INR", label: "INR-Indian Ruppe" }
]
export const language_dropdown = [
    { value: "english", label: "English" }
]
export const timeZone_dropdown = [
    { value: "(UTC+05:30) Chennai,Kolkata,Mumbai,New Delhi", label: "(UTC+05:30) Indian Standred Time (Asia/culcutta)" }
]
export const physical_year_dropdown = [
    { value: "  ", label: "April-March" }
]
export const industry_dropdown = [
    { value: "Pharmaceutical", label: "Pharmaceutical" }
]
export const number_prefix_dropdown = [
    { value: "+91", label: "india" }
]

export const HSN_dropdown = [
    { key: "1", value: "3001" },
    { key: "2", value: "3002" },
    { key: "3", value: "3003" },
    { key: "4", value: "3004" },
    { key: "5", value: "3005" }
]

export const status_dropdown = [
    { key: "1", value: "Active" },
    { key: "0", value: "Inactive" },
]
export const inventoryAdjustmentReasons = [
    { key: "1", value: "Expired Items" },
    { key: "2", value: "Damaged Goods" },
    { key: "3", value: "Theft or Loss" },
    { key: "4", value: "Administrative Errors" },
    { key: "5", value: "Return to Supplier" },
    { key: "6", value: "Dispensing Errors" },
    { key: "7", value: "Stock Reconciliation" },
    { key: "8", value: "Sample Distribution" },
    { key: "9", value: "Promotional Activities" },
    { key: "10", value: "Clinical Trials" },
    { key: "11", value: "Expired Items Disposal" },
    { key: "12", value: "Stock Transfers" },
    { key: "13", value: "Quality Control Failures" },
    { key: "14", value: "Product Recalls" },
    { key: "15", value: "Pilferage" },
    { key: "16", value: "Shortage upon Delivery" },
    { key: "17", value: "Overstock" },
    { key: "18", value: "Understock" },
    { key: "19", value: "Packaging Issues" },
    { key: "20", value: "Regulatory Compliance" },
    { key: "21", value: "Patient Returns" },
    { key: "22", value: "Inventory Optimization" },
    { key: "23", value: "Temperature Excursions" },
    { key: "24", value: "New Product Introductions" },
    { key: "25", value: "Discontinued Products" },
    { key: "26", value: "Vendor Corrections" },
    { key: "27", value: "System Errors" },
    { key: "28", value: "Donation" },
    { key: "29", value: "Waste Disposal" },
    { key: "30", value: "Other" }
];

export const inventoryAdjustmentAccount = [
    { key: "1", value: "Purchase Account" },
    { key: "2", value: "Inventory Account" },
    { key: "2", value: "Sales Account" },
    { key: "4", value: "Other" }
]

export const passwordPolicy = `
Password must contain at least one
    uppercase letter
        , one lowercase letter, 
    one number, and one special 
 character
  `
