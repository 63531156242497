import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";


export const USER_INFO_GET_DONE = "USER_INFO_GET_DONE";
export const USER_INFO_GET_FAIL = "USER_INFO_GET_FAIL";

const USER_INFO_API_URL = `${APP_API_URL}/user/info`;

let token = getLocalStorageData();
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
};

export const fetchUserInfo = () => {
    return (dispatch) => {
        return axios.get(USER_INFO_API_URL, config)
            .then((res) => {
                if (res?.data?.success) {
                    dispatch({
                        type: USER_INFO_GET_DONE,
                        payload: res.data,
                    });
                    // toast.success("User info loaded successfully!");
                    return res?.data;
                } else {
                    dispatch({
                        type: USER_INFO_GET_FAIL,
                        payload: res,
                    });
                    // toast.error("Failed to load user info");
                    return res;
                }
            }).catch((err) => {
                dispatch({
                    type: USER_INFO_GET_FAIL,
                    payload: err,
                });
                // toast.error("Failed to load user info");
                return err;
            });
    };
};
