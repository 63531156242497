import React from "react";
import PropTypes from "prop-types";
// import "./modal.scss";
import Button from "../ui/Button";

const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  content,
  className,
  cancelButton,
  handleSubmit,
  loader,
}) => {
  if (!isOpen) return null;

  return (
    <div className={`modal-backdrop ${className}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <p>{title}</p>
          <Button
            type="button"
            className="mt-0 btn-danger add-pro"
            button=""
            close
            onClick={onClose}
          />
        </div>
        <div className="my-3">
          <h3 className="modal-body">{content}</h3>
          <div className="add-productgroup-footer px-4">
            <Button
              type="submit"
              button="Save"
              className="btn-primary add-pgroup"
              loader={loader}
              onClick={handleSubmit}
            />
            <Button
              type="button"
              button="Cancel"
              className="btn-dark add-pgroup"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

ConfirmationModal.defaultProps = {
  title: "",
  content: null,
};

export default ConfirmationModal;
