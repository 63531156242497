import axios from "axios";
import { APP_API_URL } from "../../../utils/xhr-instance";
import { getLocalStorageData } from "../../../utils/helper";

export const INVENTORY_ADJUSTMENT_CREATE_DONE = "INVENTORY_ADJUSTMENT_CREATE_DONE";
export const INVENTORY_ADJUSTMENT_CREATE_FAIL = "INVENTORY_ADJUSTMENT_CREATE_FAIL";

export const INVENTORY_ADJUSTMENT_LIST_GET_DONE = "INVENTORY_ADJUSTMENT_LIST_GET_DONE";
export const INVENTORY_ADJUSTMENT_LIST_GET_FAIL = "INVENTORY_ADJUSTMENT_LIST_GET_FAIL";

export const REFERANCE_NUMBER_GET_DONE = "REFERANCE_NUMBER_DONE";
export const REFERANCE_NUMBER_GET_FAIL = "REFERANCE_NUMBER_FAIL";

export const INVENTORY_ADJUSTMENT_UPDATE_DONE = "INVENTORY_ADJUSTMENT_UPDATE_DONE";
export const INVENTORY_ADJUSTMENT_UPDATE_FAIL = "INVENTORY_ADJUSTMENT_UPDATE_FAIL";

export const INVENTORY_ADJUSTMENT_DELETE_DONE = "INVENTORY_ADJUSTMENT_DELETE_DONE";
export const INVENTORY_ADJUSTMENT_DELETE_FAIL = "INVENTORY_ADJUSTMENT_DELETE_FAIL";

export const INVENTORY_ADJUSTMENT_CONVERT_DONE = "INVENTORY_ADJUSTMENT_CONVERT_DONE";
export const INVENTORY_ADJUSTMENT_CONVERT_FAIL = "INVENTORY_ADJUSTMENT_CONVERT_FAIL";


export const INVENTORY_ADJUSTMENT_CREATE_API_URL = `${APP_API_URL}/product/inventory-adjustment`;
export const INVENTORY_ADJUSTMENT_LIST_API_URL = `${APP_API_URL}/product/inventory-adjustment/list`;
export const INVENTORY_ADJUSTMENT_UPDATE_API_URL = `${APP_API_URL}/product/inventory-adjustmtent/update`;
export const INVENTORY_ADJUSTMENT_DELETE_API_URL = `${APP_API_URL}/product/inventory-adjustment/delete`;
export const INVENTORY_ADJUSTMENT_CONVERT_API_URL = `${APP_API_URL}/product/inventory-adjustment/convert-to-adjustment`;
export const INVENTORY_ADJUSTMENT_REVERT_API_URL = `${APP_API_URL}/product/inventory-adjustment/revert-to-adjustment`;
export const REFERANCE_NUMBER_API_URL = `${APP_API_URL}/product/get-reference-number`;



let token = getLocalStorageData();
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.token}`,
    },
};


// Create Inventory Adjustment
export const inventoryAdjustmentCreate = (formBody) => {
    let API_URL = INVENTORY_ADJUSTMENT_CREATE_API_URL;
    return (dispatch) => {
        return axios.post(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CREATE_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CREATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_CREATE_FAIL,
                payload: err,
            });
            return err;
        });
    };
};

// List Inventory Adjustments
export const inventoryAdjustmentList = (param) => {
    let API_URL = `${INVENTORY_ADJUSTMENT_LIST_API_URL}?${param}`;
    return (dispatch) => {
        return axios.get(API_URL, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_LIST_GET_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_LIST_GET_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_LIST_GET_FAIL,
                payload: err,
            });
            return err;
        });
    };
};

// Update Inventory Adjustment
export const inventoryAdjustmentUpdate = (id, formBody) => {
    let API_URL = `${INVENTORY_ADJUSTMENT_UPDATE_API_URL}/${id}`;
    return (dispatch) => {
        return axios.put(API_URL, formBody, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_UPDATE_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_UPDATE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_UPDATE_FAIL,
                payload: err,
            });
            return err;
        });
    };
};

// Delete Inventory Adjustment
export const inventoryAdjustmentDelete = (id) => {
    let API_URL = `${INVENTORY_ADJUSTMENT_DELETE_API_URL}/${id}?is_delete=1`;
    return (dispatch) => {
        return axios.delete(API_URL, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_DELETE_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_DELETE_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_DELETE_FAIL,
                payload: err,
            });
            return err;
        });
    };
};

export const inventoryAdjustmentConvert = (id) => {
    let API_URL = `${INVENTORY_ADJUSTMENT_CONVERT_API_URL}/${id}`;
    return (dispatch) => {
        return axios.post(API_URL, {}, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CONVERT_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CONVERT_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_CONVERT_FAIL,
                payload: err,
            });
            return err;
        });
    };
};
// revert to adjustment 
export const inventoryAdjustmentReverted = (id) => {
    let API_URL = `${INVENTORY_ADJUSTMENT_REVERT_API_URL}/${id}`;
    return (dispatch) => {
        return axios.post(API_URL, {}, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CONVERT_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: INVENTORY_ADJUSTMENT_CONVERT_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: INVENTORY_ADJUSTMENT_CONVERT_FAIL,
                payload: err,
            });
            return err;
        });
    };
};
export const getRefNumber = (param) => {
    let API_URL = REFERANCE_NUMBER_API_URL
    if (param) {
        API_URL = `${REFERANCE_NUMBER_API_URL}?${param}`;
    }
    return (dispatch) => {
        return axios.get(API_URL, config).then((res) => {
            if (res?.data?.success) {
                dispatch({
                    type: REFERANCE_NUMBER_GET_DONE,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: REFERANCE_NUMBER_GET_FAIL,
                    payload: res,
                });
                return res;
            }
        }).catch((err) => {
            dispatch({
                type: REFERANCE_NUMBER_GET_FAIL,
                payload: err,
            });
            return err;
        });
    };
};