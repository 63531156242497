import { MASTER_DATA_DONE } from "../../action/master";
import { HSN_LIST_GET_DONE } from "../../action/utility";

const initState = {
    data: [],
    error: null,
    status: null,
    currentlogedInuser: null,
};

export const utilityReducer = (state = initState, action) => {
    switch (action.type) {
        case HSN_LIST_GET_DONE:
            return {
                ...state,
                data: {
                    data: action?.payload?.data?.data,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
        default:
            return state;
    }
};
