import { configureStore } from '@reduxjs/toolkit'
import { masterReducer } from '../reducers/master'
import { productGroupReducer } from '../reducers/group/Index'
import { productCatogeryReducer } from '../reducers/catogery'
import { shelfReducer } from '../reducers/shelf'
import { productReducer } from '../reducers/product'
import { utilityReducer } from '../reducers/utility'
import { batchReducer } from '../reducers/batch'
import { inventoryAdjustmentReducer } from '../reducers/inventory'
import userReducer from '../reducers/userInfo'
// import { counterReducer } from '../action/auth'



export default configureStore({
  reducer: {
    master: masterReducer,
    productGroup: productGroupReducer,
    productCatogery: productCatogeryReducer,
    shelf: shelfReducer,
    product: productReducer,
    utility: utilityReducer,
    batch: batchReducer,
    inventory: inventoryAdjustmentReducer,
    userInfo:userReducer
  }
})