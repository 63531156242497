// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  overflow-y: auto;
  max-height: 89vh;
}

.dashboard-container > div > div {
  background-color: #FFF;
  border: 2px solid;
}

.frist-row {
  height: 80px;
  margin: 20px;
}

.second-row {
  height: 80px;
  margin: 20px;
}

.third-row {
  height: 300px;
  margin: 20px;
}

.fourth-row {
  height: 230px;
  margin: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboardstyle.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,sBAAA;EAEA,iBAAA;AAAJ;;AAIA;EACI,YAAA;EACA,YAAA;AADJ;;AAIA;EACI,YAAA;EAEA,YAAA;AAFJ;;AAOA;EACI,aAAA;EAEA,YAAA;AALJ;;AAUA;EACI,aAAA;EAEA,YAAA;AARJ","sourcesContent":[".dashboard-container {\n    overflow-y: auto;\n    max-height: 89vh;\n}\n\n.dashboard-container > div >div {\n    background-color: #FFF;\n    // padding: 10px;\n    border: 2px solid;\n\n}\n\n.frist-row {\n    height: 80px;\n    margin: 20px;\n}\n\n.second-row {\n    height: 80px;\n\n    margin: 20px;\n\n\n}\n\n.third-row {\n    height: 300px;\n\n    margin: 20px;\n\n\n}\n\n.fourth-row {\n    height: 230px;\n\n    margin: 20px;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
